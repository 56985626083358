import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IBFormuTesekkur_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IBFormuTesekkur_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  sbBasvuruTuruList: any[];
  sbBasvuruTuruList_dummy: any[];
  sbProjeFormTuruList: any[];
  sbProjeFormTuruList_dummy: any[];
  isLoggedIn: boolean;
  isLoggedIn_dummy: boolean;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  isComp905702Visible: "visible" | "hidden";
  isComp283175Visible: "visible" | "hidden";
  isCompbformutesekkur_905702AuthorizationVisible: "visible" | "hidden";
  isCompbformutesekkur_283175AuthorizationVisible: "visible" | "hidden";
}

export class BFormuTesekkur_ScreenBase extends React.PureComponent<IBFormuTesekkur_ScreenProps, any> {
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "b811f7bf-e3bd-4975-b3e0-f58f15e67c5d",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 465757, PropertyName: "value", Value: "Başvurunuz için teşekkür ederiz." },
        { Id: 758498, PropertyName: "value", Value: "En kısa zamanda sizinle iletişime geçeceğiz." },
        { Id: 198001, PropertyName: "value", Value: "Thank you for your submission." },
        { Id: 414133, PropertyName: "value", Value: "We’ll be in touch as soon as possible." },
        { Id: 905702, PropertyName: "value", Value: "Yeni Başvurular Sayfasına Git" },
        { Id: 283175, PropertyName: "value", Value: "Yeni Başvurular Sayfasına Git" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      sbBasvuruTuruList: [],
      sbProjeFormTuruList: [],
      isLoggedIn: false,
      NAVIGATE: "",
      isComp905702Visible: "hidden",
      isComp283175Visible: "hidden",
      isCompbformutesekkur_905702AuthorizationVisible: "visible",
      isCompbformutesekkur_283175AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("bformutesekkur", "");
      return;
    }

    if (true) {
      await this.BFormuTesekkurPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompbformutesekkur_905702AuthorizationVisible", [
      "49d4d8a3-3887-e438-5aa7-143306fb208a",
      "Müşteri"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompbformutesekkur_283175AuthorizationVisible", [
      "7165e0ad-e354-6630-d176-a4378c7ac2b9",
      "89f0bd8c-62b8-cf33-3763-edf7319ca78f",
      "5702a90f-4edb-07c0-5096-8ee6a5c813e6",
      "3699f6cb-db2c-cd20-74c6-a9ef5bd8a887",
      "Muhasebe",
      "Admin",
      "Muz Ekip A",
      "Muz Ekip B"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("bformutesekkur", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("bformutesekkur", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.BFormuTesekkurPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  BFormuTesekkurPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "BFormuTesekkur/BFormuTesekkurPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sbBasvuruTuruList = result?.data.sbBasvuruTuruList;
    stateVars.sbProjeFormTuruList = result?.data.sbProjeFormTuruList;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BFormuTesekkurPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BFormuTesekkurPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.isLoggedIn = await ReactSystemFunctions.isLoggedIn();
    stateVars.isComp905702Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, stateVars.isLoggedIn), true) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283175Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, stateVars.isLoggedIn), true) === true
        ? "visible"
        : "hidden";
    stateVars.isComp905702Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, stateVars.isLoggedIn), true) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283175Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, stateVars.isLoggedIn), true) === true
        ? "visible"
        : "hidden";

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  BFormuTesekkurComponent_905702_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "BFormuTesekkur",
      "TicketListBayi",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BFormuTesekkurComponent_283175_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "BFormuTesekkur",
      "TicketList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
